import { AMOUNT_TYPE_VEHICLE } from '@/utils/enum';
import { unMaskPrice } from '@/utils/common';

const stockDiscountMixin = {
  created: function() {
  },
  data() {
    return {
      selectDiscountType: AMOUNT_TYPE_VEHICLE.AMOUNT,
      listAmountType: [
        {
          id: AMOUNT_TYPE_VEHICLE.AMOUNT,
          name: 'Tiền mặt',
        },
        {
          id: AMOUNT_TYPE_VEHICLE.PERCENT,
          name: '%',
        },
      ],
    };
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total +=
          unMaskPrice(element.price) * element.quantity -
          unMaskPrice(element.discount);
      }
      const discountValue = this.discountAmount
        ? unMaskPrice(this.discountAmount)
        : 0;
      let discountAmount = discountValue;
      if (this.selectDiscountType === AMOUNT_TYPE_VEHICLE.PERCENT) {
        discountAmount = (discountValue * total) / 100;
      }

      total = total - discountAmount;
      const vatValue = this.vatAmount ? unMaskPrice(this.vatAmount) : 0;

      let vatAmount = vatValue;
      if (this.selectVatType === AMOUNT_TYPE_VEHICLE.PERCENT) {
        vatAmount = (vatValue * total) / 100;
      }

      total = parseInt(total) + parseInt(vatAmount);
      return total;
    },
  },
};

export default stockDiscountMixin;
