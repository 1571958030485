import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import { STATUS_CODE } from '@/utils/enum';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';

const stockApiCommonMixin = {
  methods: {
    fetchProvider() {
      ApiService.get(
        `${cmdUrl.ProviderUrl.root}?search=${this.searchProvider}`,
      ).then(({ data }) => {
        this.listProviders = data.data.data;
        this.optionsProvider[0].data = this.listProviders.map((element) => {
          return {
            id: element.id,
            name: element.name,
            suggestionName: element.name,
            numPaymentDate: element.numPaymentDate,
          };
        });
        this.filteredOptionsProvider = [...this.optionsProvider[0].data];
      });
    },
    createProvider: async function () {
      if (!this.providerName || this.isSubmitting) return;
      this.isSubmitting = true;
      const data = {
        name: this.providerName,
        phoneNumber: this.providerPhone,
      };

      try {
        const response = await ApiService.post('providers', data);
        const { status, message } = response.data.data;
        if (status === STATUS_CODE.SUCCESS) {
          this.fetchProvider();
          this.showOriginalModel = !this.showOriginalModel;
          makeToastSuccess(message);
        } else {
          this.showOriginalModel = !this.showOriginalModel;
          makeToastFaile(message);
        }
      } catch (err) {
        const { message } = err.response.data;
        return makeToastFaile(message || 'Lỗi');
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
export default stockApiCommonMixin;
