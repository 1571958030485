<template>
  <div>
    <b-modal
      ref="warning-po-modal"
      hide-footer
      :title="title"
      id="modal-prevent-closing"
    >
      <b-table bordered hover :fields="fields" :items="items"> </b-table>
      <b-button
        style="fontweight: 600; width: 70px"
        variant="primary"
        size="sm"
        @click="onSubmit"
        >Vẫn lưu</b-button
      >
      <b-button
        style="margin-left: 10px; font-weight: 600; width: 70px"
        variant="secondary"
        size="sm"
        @click="hideModal"
        >Hủy</b-button
      >
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: 'Cảnh báo số lượng nhập không khớp với PO',
      items: [],
      fields: [
        {
          key: 'productName',
          label: 'Tên',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '60%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'poQuantity',
          label: 'SL yêu cầu',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'stockSlipQuantity',
          label: 'SL nhập',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-right',
        },
      ],
    };
  },
  methods: {
    showModal: function (warningItems) {
      this.items = warningItems;
      this.$refs['warning-po-modal'].show();
    },
    hideModal: function () {
      this.$refs['warning-po-modal'].hide();
    },
    onSubmit() {
      this.$emit('onContinute');
      this.hideModal();
    },
  },
};
</script>