<template>
  <thead>
    <tr>
      <th scope="col" class="title-center" style="width: 15%">Tên sản phẩm</th>
      <th scope="col" class="title-center" style="width: 5%">Tồn</th>
      <th scope="col" class="title-center" style="width: 20%">
        Số khung / Số serial
      </th>
      <th scope="col" class="title-center" style="width: 10%">Số lượng</th>
      <th scope="col" class="title-center" style="width: 18%">Đơn giá</th>
      <th scope="col" class="title-center" style="width: 14%">Thành tiền</th>
      <th scope="col" class="title-center" style="width: 13%">Chiết khấu</th>
      <th scope="col" class="title-center" style="width: 5%"></th>
    </tr>
  </thead>
</template>
<script>
export default {};
</script>
